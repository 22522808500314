import * as HttpService from "../../service/HttpService";
/**
 * 查询订单使用记录
 * @param params 参数列表
 */
export const queryCellOrder = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/cellOrder/queryCellOrder", params);
};
/**
 * 查询订单使用记录详情
 * @param params 参数列表
 */
export const queryCellOrderById = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/cellOrder/queryCellOrderById", params);
};
/**
 * 查询交易订单
 * @param params 参数列表
 */
 export const getTradeOrder = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/tradeOrder/queryTradeOrder", params);
};

/**
 *查询交易退款
 * @param params 参数列表
 */
 export const queryTradeOrderRefund = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/tradeOrder/queryTradeOrderRefund", params);
};
/**
 *手动退款
 * @param params 参数列表
 */
 export const manualRefund = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/tradeOrder/manualRefund", params);
};

/**
 *手动结束进行中订单
 * @param params 参数列表
 */
 export const endCellOrder = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/cellOrder/endCellOrder", params);
};
