import * as HttpService from "../../service/HttpService";
/**
 * 查询电站管理列表
 * @param params 参数列表
 */
export const getelecStationPage = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/elecStation/selectElecCabinetPage", params);
};
/**
 * 新增换电站
 * @param params 参数列表
 */
export const addElecStation = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/elecStation/save", params);
};
/**
 *编辑换电站
 * @param params 参数列表
 */
export const editElecStation = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/elecStation/update", params);
};
/**
 *自动获取电站编号
 */
export const getAutomatStationNo = () => {
  return HttpService.get(HttpService.DOMAIN + "/gaea-cabinet/elecStation/getAutomatStationNo");
};
/**
 *删除换电柜
 * @param params 参数列表
 */
export const removeElecStation = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/elecStation/remove", params);
};
/**
 *下发地址
 * @param params 参数列表
 */
 export const sendCabinetAddress = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/elecCabinet/sendCabinetAddress", params);
};
/**
 * 查询电柜管理列表
 * @param params 参数列表
 */
export const getElecCabinetPage = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/elecCabinet/selectElecCabinetPage", params);
};
/**
 * 新增换电柜
 * @param params 参数列表
 */
export const addCabinet = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/elecCabinet/save", params);
};

/**
 *编辑换电柜
 * @param params 参数列表
 */
export const editCabinet = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/elecCabinet/update", params);
};
/**
 *删除换电柜
 * @param params 参数列表
 */
export const removeCabinet = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/elecCabinet/remove", params);
};
/**
 *仓室监测
 * @param params 参数列表
 */

export const getElecCellDetailList = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/elecCabinet/getElecCellDetailList", params);
};
/**
 *电柜监测实时数据
 * @param params 参数列表
 */

export const getMonitorRealTimeData = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/elecCabinet/getMonitorRealTimeData", params);
};
/**
 *数据采集时间
 * @param params 参数列表
 */

export const getElecCabinetTime = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/elecCabinet/getElecCabinetTime", params);
};
/**
 *电柜监测电柜监测曲线
 * @param params 参数列表
 */

export const getElecCabinetMonitorList = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/elecCabinet/getElecCabinetMonitorList", params);
};
/**
 *消防监测数据项实时数据
 * @param params 参数列表
 */

export const getFireProtectMeasItemCodeValueData = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/elecCabinet/getFireProtectMeasItemCodeValueData", params);
};
/**
 * 查询电池管理列表
 * @param params 参数列表
 */
export const getElecCellPage = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/elecCell/selectElecCellPage", params);
};
/**
 * 新增电池
 * @param params 参数列表
 */
export const addBattery = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/elecCell/save", params);
};

/**
 *编辑电池
 * @param params 参数列表
 */
export const editBattery = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/elecCell/update", params);
};
/**
 *删除电池
 * @param params 参数列表
 */
export const removeBattery = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/elecCell/remove", params);
};
/**
 *修改电池状态
 * @param params 参数列表
 */
export const updateStatus = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/elecCell/updateStatus", params);
};
/**
 *电柜设备工况分页查询
 * @param params 参数列表
 */
export const getOpsDevCabinetPage = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/opsDevCabinet/selectOpsDevCabinetPage", params);
};

/**
 *视频监测
 * @param params 参数列表
 */
export const videoPlayBackByDeviceSerial = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/elecVideoMonitor/videoPlayBackByDeviceSerial", params);
};
/**
 *视频监测
 * @param params 参数列表
 */
 export const videoStop = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/elecVideoMonitor/videoStop", params);
};

/**
 *新增修改扩展表SOC阈值
 * @param params 参数列表
 */
export const saveOrUpdate = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/elecCabinetSetting/saveOrUpdate", params);
};

/**
 *换电柜设置soc阈值
 * @param params 参数列表
 */
export const getOrgExtList = () => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/elecCabinetSetting/getOrgExtList");
};
/**
 *设置换电柜
 http://yapi.ebike-charge.com:6060/project/437/interface/api/15250
 * @param params 参数列表
 */
export const setBatterySwapCabinet = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/cabinetSettings/setBatterySwapCabinet", params);
};
/**
 *获取换电柜设置
 http://yapi.ebike-charge.com:6060/project/437/interface/api/15254
 * @param params 参数列表
 */
export const getBatterySwapCabinetSettings = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/cabinetSettings/getBatterySwapCabinetSettings", params);
};
/**
 *表计管理分页查询
 @param params 参数列表
 */
export const selectElecMeterPage = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + '/gaea-cabinet/elecMeter/selectElecMeterPage', params)
}
/**
 *监控设备管理分页查询
 */
export const selectElecCameraPage = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + '/gaea-cabinet/elecCamera/selectElecCameraPage', params)
}
/**
 *新增表计
 http://yapi.ebike-charge.com:6060/project/437/interface/api/15274
 * @param params 参数列表
 */
 export const saveElecMeter = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/elecMeter/saveElecMeter",params);
};
/**
 *修改表计
 http://yapi.ebike-charge.com:6060/project/437/interface/api/15274
 * @param params 参数列表
 */
 export const updateElecMeter = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/elecMeter/updateElecMeter",params);
};
/**
 *删除表计
 http://yapi.ebike-charge.com:6060/project/437/interface/api/15274
 * @param params 参数列表
 */
 export const deleteElecMeter = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/elecMeter/deleteElecMeter",params);
};
/**
 *新增监控设备
 http://yapi.ebike-charge.com:6060/project/437/interface/api/15294
 * @param params 参数列表
 */
 export const saveElecCamera = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/elecCamera/saveElecCamera",params);
};
/**
 *删除监控设备
 http://yapi.ebike-charge.com:6060/project/437/interface/api/15294
 * @param params 参数列表
 */
 export const deleteElecCamera = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/elecCamera/deleteElecCamera",params);
};
/**
 *修改监控设备
 http://yapi.ebike-charge.com:6060/project/437/interface/api/15294
 * @param params 参数列表
 */
 export const updateElecCamera = (params) => {
  return HttpService.post(HttpService.DOMAIN + "/gaea-cabinet/elecCamera/updateElecCamera",params);
};
// 获取换电柜关联套餐列表
export const getSwapCabinetPackages = (params) => {
  return HttpService.getNormal(HttpService.DOMAIN + "/gaea-cabinet/elecCabinet/getSwapCabinetPackages",params);
};